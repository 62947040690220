import { useTranslation } from 'react-i18next';
import { Button, Typography as T } from '../../../components';
import { common, navRoutes } from '../../../constants';
import theme from '../../../theme';
import * as S from './style';

const UniversalCreditCard = () => {
  const { t } = useTranslation();

  return (
    <S.FullSection color={theme.colors.primaryLight}>
      <S.CardContentWrapper>
        <T.H2 color="neutralMain" ta="center" taM="start" mb="4">
          {t(
            'common.section.extraSupport.title',
            common.section.extraSupport.title
          )}
        </T.H2>

        <T.P ta="center" mb={3}>
          {t(
            'common.section.extraSupport.description',
            common.section.extraSupport.description
          )}
        </T.P>

        <Button
          external
          to={navRoutes.EXTERNAL.UNIVERSAL_CREDIT}
          icon="forwardArrow"
          text={t(
            'common.buttons.checkUniversalCredit',
            common.buttons.checkUniversalCredit
          )}
        />
      </S.CardContentWrapper>
    </S.FullSection>
  );
};

export default UniversalCreditCard;
